import axios from "axios";
import i18n from "./i18n";

const axiosInstance = axios.create({
  responseType: "json",
  baseURL: "https://api.ticket.bergregions.pl/api/v1/",
  timeout: 1000 * 20,
  headers: { "Accept-Language": i18n.resolvedLanguage },
  paramsSerializer: { indexes: null },
});

axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosInstance.defaults.headers.put["Content-Type"] = "application/json";
axiosInstance.defaults.headers.get["Content-Type"] = "application/json";

export default axiosInstance;
